import axios from 'axios'
import {
    IEmployer,
    IEmployerResponse,
    ILocation,
} from '../Models/Employer.model'
import { IJob } from '../Models/Job.model'

export const getEmployer = async (
    id: string
): Promise<any> => {
    try {
        const { data } = await axios.get(`/public-jobs/getEmployerById/${id}`)
        return data
    } catch (err) {
        console.log(`error from controller ${err}`)
    }
}

export const getJobsByEmployerId = async (
    id: string
): Promise<any> => {
    try {
        const { data } = await axios.get(`/public-jobs/byEmployer/${id}`)
        return data;
    } catch (err) {
        console.log(`error from controller ${err}`)
    }
}

export const getJobById = async (
    id: string
): Promise<any> => {
    try {
        const { data } = await axios.get(`/public-jobs/${id}`)
        return data
    } catch (err) {
        console.log(`error from controller ${err}`)
    }
}
