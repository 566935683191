import NetworkManager from "./Utils/networkManager";
import React, { useState, useEffect, useRef } from "react";
import logo from "./logo.svg";
import "./App.css";
import JobPage from "./Routes/JobPage/JobPage";
import EmployerPage from "./Routes/EmployerPage/EmployerPage";
import {
  Route,
  Routes,
  Link,
  useNavigate,
  useParams,
  Outlet,
} from "react-router-dom";
import { Strings } from "./Constants";
import backdrop from "./Routes/JobPage/Assets/backdrop.png";
import marker from "./Routes/JobPage/Assets/marker.png";
// import window from "./Routes/JobPage/Assets/window.png";
import payment from "./Routes/JobPage/Assets/payment.png";
import location from "./Routes/JobPage/Assets/location.png";
import calendar from "./Routes/JobPage/Assets/calendar.png";
import share from "./Routes/JobPage/Assets/share.png";
import { getEmployer, getJobById } from "./Controllers/apiController";
import { IJob } from "./Models/Job.model";

const App: React.FC = () => {
  // const tempEmployerId = ;
  const tempJobId = "61d5af2bae529a0009b7c580";

  useEffect(() => {
    NetworkManager.init();
    // const getEmployerExample = getEmployer(tempEmployerId);
    // tempFetch();
  }, []);

  const tempFetch = async () => {
    const jobByIdExample: IJob = await getJobById(tempJobId);
    setCurrJob(jobByIdExample);
    console.log("jobByIdExample: ", jobByIdExample);
  };

  const title = Strings.Navbar.APP_TITLE;
  const [currEmployer, setCurrEmployer] = useState({});

  const [currJob, setCurrJob] = useState<IJob>();
  const [currJobId, setCurrJobId] = useState({});
  const [currJobType, setCurrJobType] = useState();
  const [currJobSkills, setCurrJobSkills] = useState();
  const [currJobCategories, setCurrJobCategories] = useState();

  const jobUrl = "/public-jobs/:id";
  const allJobsUrl = "/public-jobs/";
  const jobsByEmployer = "/public-jobs/byEmployer/:id";
  const employerById = "/public-jobs/getEmployerById/:id";
  const jobType = "/public-jobs/jobTypes";
  const jobSkills = "/public-jobs/skills";
  const jobCategories = "/public-jobs/categories";

  const fetchCurrJobSetUrl = () => {
    // await ...
    setCurrJob(currJob);
    // setCurrJobId(tempData._id.$oid)
    // setCurrJobType(job.type.name)
    // setCurrJobSkills(#)
    // setCurrJobCategories(#)
  };

  const fetchCurrEmployer = () => {
    // await ...
    // setCurrEmployer(#);
  };

  const tempData = {
    _id: {
      $oid: "61d5af2bae529a0009b7c580",
    },
    tags: [
      {
        $oid: "60cb704f131a48158de3d8f0",
      },
      {
        $oid: "60cb7c89945ca915d1843e36",
      },
    ],
    isMulti: false,
    skills: [],
    shifts: [],
    status: 1,
    employerName: "היייייי",
    employerEmail: "test555@deebapp.com",
    employerPhoneNumber: "0546512018",
    type: {
      $oid: "60cb7be1945ca915d1843e2c",
    },
    description: "sadas",
    eventName: "",
    location: {
      coordinates: [31.804381, 34.655314],
      _id: {
        $oid: "61d5af2bae529a0009b7c581",
      },
      type: "Point",
      address: "אשדוד, ישראל",
      floor: "",
    },
    payment: 30,
    paymentType: "hourly",
    startDate: {
      $date: "2022-01-20T06:00:00.000Z",
    },
    endDate: {
      $date: "2022-01-20T11:00:00.000Z",
    },
    dressCode: "",
    isTips: false,
    isBill: false,
    openPosiotions: 3,
    numOfPosiotions: 3,
    employer: {
      $oid: "61b9fead18578f00098b0db4",
    },
    createdAt: {
      $date: "2022-01-05T14:46:03.819Z",
    },
    updatedAt: {
      $date: "2022-01-05T20:35:34.321Z",
    },
    __v: 0,
  };

  useEffect(() => {
    fetchCurrJobSetUrl();
    fetchCurrEmployer();
    return () => {};
  }, []);

  return (
    <div className="App">
      {/* <div className="App-header">
        <p className="Header-Text">{title}</p>
      </div> */}

      <Routes>
        {/* <Route path="/" element={<App />} /> */}
        <Route path="/jobs/*" element={<JobPage />} />
        {/* {
          "https://jobs.deebapp.com/jobs/?pid=User_Invite_Web&af_referrer_customer_id=61e1a258e1833d00091897dd&jobId=61cc6762f5f73000080fc985&employerId=61929670960ef60ee0f0333a&shortlink=jobweb&c=webshare",
          "https://installs.onelink.me/lQzQ?pid=User_Invite_Web&c=webshare&af_web_dp=https%3A%2F%2Fjobs.deebapp.com%2Femployers%2F"
        } */}
        {/* <Route path="/employers/:employerId" element={<EmployerPage />} /> */}
      </Routes>
      {/* 
        <Link to="/">Home</Link> |{" "}
        <Link to="about">About</Link>
        function Invoices() {
        let navigate = useNavigate();
        return (
              <div>
                <NewInvoiceForm
                  onSubmit={async event => {
                    let newInvoice = await createInvoice(
                      event.target
                    );
                    navigate(`/invoices/${newInvoice.id}`);
                  }}
                />
            </div>
          );
        }
        function Invoice() {
          let { invoiceId } = useParams();
          let invoice = useFakeFetch(`/api/invoices/${invoiceId}`);
          return invoice ? (
              <div>
                <h1>{invoice.customerName}</h1>
              </div>
          ) : (
            <Loading />
          );
        }
      */}
    </div>
  );
};

export default App;
