import { Strings } from ".";

export const environment: String = Strings.ENVIRONMENT.STAGING;

interface Config {
  general: {
    apiUrl: string;
    cloudinary: string;
  };
  aws: {
    userPoolId: string;
    appClientId: string;
    bucket: string;
    key: string;
    secret: string;
    domain: string;
  };
  google: {
    places: string;
  };
}

export function getConfig(): Config {
  switch (environment) {
    case Strings.ENVIRONMENT.STAGING: {
      return {
        general: {
          apiUrl: "https://stgapi.deebjob.com/api/v1",
          // apiUrl: 'http://localhost:3000',
          cloudinary: "profile-img-dev",
        },
        aws: {
          userPoolId: "eu-west-1_6VpoaO9XQ",
          appClientId: "5v96ds10co40ci9ptlqkg1r8op",
          bucket: "profile-img-dev",
          key: "AKIAWIJZVQDEHL4UN4UJ",
          secret: "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",
          domain: "deeb-app.auth.eu-west-1.amazoncognito.com",
        },
        google: {
          places: "AIzaSyCaL1vddMj-AbDmi_063BA6GnpZCEfR2Lk",
        },
      };
    }
    case Strings.ENVIRONMENT.PROD: {
      return {
        general: {
          apiUrl: "https://api.deebjob.com/api/v1",
          cloudinary: "profile-img-prod",
        },
        aws: {
          userPoolId: "eu-west-1_8bmAW8j8f",
          appClientId: "sdlcee6qv45gc4o0dkqv6g54c",
          bucket: "profile-img-prod",
          key: "AKIAWIJZVQDEHL4UN4UJ",
          secret: "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",
          domain: "deeb-prod.auth.eu-west-1.amazoncognito.com",
        },
        google: {
          places: "AIzaSyCaL1vddMj-AbDmi_063BA6GnpZCEfR2Lk",
        },
      };
    }
    default: {
      return {
        general: {
          apiUrl: "https://devapi.deebjob.com/api/v1",
          //   apiUrl: 'http://localhost:3000',
          cloudinary: "profile-img-dev",
        },
        aws: {
          userPoolId: "eu-west-1_6VpoaO9XQ",
          appClientId: "5v96ds10co40ci9ptlqkg1r8op",
          bucket: "profile-img-dev",
          key: "AKIAWIJZVQDEHL4UN4UJ",
          secret: "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",
          domain: "deeb-app.auth.eu-west-1.amazoncognito.com",
        },
        google: {
          places: "AIzaSyCaL1vddMj-AbDmi_063BA6GnpZCEfR2Lk",
        },
      };
    }
  }
}
