// /* eslint-disable @typescript-eslint/no-unused-vars */
import { getConfig } from "../Constants/config";
import axios from "axios";

const MAX_REQUESTS_COUNT = 4;

export default class NetworkManager {
  static async init() {
    //set base url
    axios.defaults.baseURL = getConfig().general.apiUrl;
    // set 401 handler
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        const originalRequest = error.config;
        if (error?.response?.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          originalRequest.headers.retryNumber = originalRequest.headers
            .retryNumber
            ? originalRequest.headers.retryNumber + 1
            : 1;
          console.log(
            "originalRequest.headers.retryNumber",
            originalRequest.headers.retryNumber
          );
          if (originalRequest.headers.retryNumber < MAX_REQUESTS_COUNT) {
            return (
              axios(originalRequest)
                // eslint-disable-next-line no-shadow
                .catch((error) =>
                  console.log("You need to login, error= ", error)
                )
            );
          }
        } else return error;
      }
    );
  }
}
