export const GENERAL = {
  UNKNOWN: "unknown",
  BACK: "חזרה",
  ERROR: "ארעה שגיאה, נסה שוב מאוחר יותר",
};

export const ENVIRONMENT = {
  STAGING: "staging",
  DEV: "dev",
  PROD: "prod",
};

export const Navbar = {
  APP_TITLE: 'Deeb',
};

export const EmployerProfile = {
  FULLY_BOOKED_JOBS: "משרות מאויישות",
  NOT_FULLY_BOOKED_JOBS: "משרות לא מאויישות",
  JOB_HISTORY: "למשרות שעברו",
  BUSINESS_NAME: "שם עסק",
  ADRESS: "כתובת",
  CONTACT: "איש קשר",
  PHONE: "טלפון",
  BUSINESS_ID: "ח.פ",
  LOCATION: "שם מקום",
};

export const Screens = {};

export const BasicInfo = {
  TITLE: "יצירת עסק חדש",
  EMPLOYER_NAME: "שם עסק",
  CONTACT_NAME: "שם איש קשר",
  BUSINESS_NUMBER: "מזהה עסק",
  BUSINESS_TYPE: "סוג העסק",
  LOCATION: "כתובת",
  PHONE: "טלפון איש קשר",
  INPUT_ERROR: "קצר מידי",
};

export const JobDetailsPage = {
  GENERAL_TEXT: "משרה נוצרה לפני",
  DAYS: "ימים",
  HOURS: "שעות",
  JOB_DETAILS: "פרטי משרה",
  MULTI_DAY: "רב יומי",
};

export const JobSearchPage = {
  JOB_SEARCH_TITLE: "מציאת משרות ברגע, בלי לוותר על החופש שלך :)",
  WHAT_IS_INTERESTING: "מה מעניין אותך?",
  TELEPHONIST: "טלפנות",
  SALES: "מכירות",
  NIGHT: "שעות הלילה",
  EVENT: "אירועים",
  SECURITY: "אבטחה",
  DELIVARY: "שליחויות",
  RESTAURANTS: "מסעדנות",
  HOT: "רותח",
  CLOSED_POSITION: "המשרה אוישה",
  TIPS: "יש טיפים!",
  GLOBAL: " ₪ גלובאלי",
  PER_HOUR: " ₪ לשעה",
  GLOBAL_NO: "גלובאלי",
  PER_HOUR_NO: "לשעה",
  UPDATE_BEFORE: "עודכן לפני",
  OPEN_POSITIONS: "משרות פתוחות!",
  SEARCH: "מה מחפשים?",
  HELLO: "שלום",
  DEEB_NOW: "Deeb !עכשיו",
  MAKE_WAY: "עושים דרך",
  DEEB_SMALL: "דיב בקטנה",
  DEEB_EXPENSIVELY: "דיב בביוקר",
  SALE_NOW: "מוכרים עכשיו",
  DEEB_STRONG: "DEEB לחזקים",
  DEEB_AIRCONDITIONER: "DEEB במזגן",
  DEEB_OUT_OF_CITY: "DEEB מחוץ לעיר",
  DEEB_POWER: "deebers שבכושר",
  DEEB_FOR_ALL: "deebers לכולם",
  DEEB_KITCHEN: "DEEB במטבח",
};
export const JobPage = {
  DAY: "יום",
  DETAILS: "מה מי מו?",
  BOOKED: "משרות אוישו",
  REQUIREMENT: "דרישות",
  DRESS_CODE: "קוד לבוש",
  CANDIDATES_LIST: "רשימת מועמדים",
  CANCEL: "התחרטתי, מעדיף לוותר",
  CATCH_JOB_MESSAGE: "התקבלת למשמרת הזאת!",
  REQUEST_SUBMITTED: "שלחת בקשה למשרה הזו",
  CONFIRMATION: "השאר.י מעודכנ.ת, התשובה תתקבל בקרוב",
  POSITION_FULL: "המשרה מלאה",
  POSITION_FULL_INFO: "לא ניתן להרשם אליה יותר",
  OPEN_POSITIONS: "משרות פנויות! הירשמו מהר לפני שייתפסו :)",
  DONT_HAVE_REQUIREMENTS: "הלכת רחוק מידי 😅 ",
  hours: "שעות",
  days: "ימים",
  CONTACT_US: "דברו איתנו",
  DETAILS_CARD: "לפרטים",
  JOB_DESCRIPTION: "תיאור המשרה",
  JOB_DETAILS: "פרטי משרה",
  NO_REQUIREMENTS: "למשרה זו אין דרישות",
  COMING_TO_WORK: "באים לעבוד",
  NO_APPROVED_CANDIDATES: "אף מועמד לא אושר",
};

export const Settings = {
  SETTINGS: "הגדרות",
  TERMS_OF_USE: "תנאי שימוש",
  PRIVACY_TERMS: "תנאי פרטיות",
  CONTACT_US: "צור קשר",
  LOGOUT: "התנתקות",
  TERMS_OF_USE_TITLE: "תנאי שימוש באפליקציה",
  PRIVACY_TERMS_TITLE: "תנאי פרטיות של דיב",
  CONTACT_US_TITLE: "נתקלתם בבעיה? אנחנו פה :)",
  CONTACT_US_INFO:
    "מוזמנים לשלוח לנו הודעה בוואטצאפ, נשתדל לענות לכם בהקדם האפשרי!",
  CONTACT_US_BUTTON: "דברו איתנו",
  SUPPORT: "עזרה ותמיכה",
};

export const NoConnection = {
  NoConnectionTitle: "אי אפשר להתחבר לאינטרנט",
  NoConnectionInfo: "מומלץ לבדוק את החיבור",
  NoConnectionButton: "רוצה לנסות שוב",
};

export const JobPaymentForm = {};
