import NetworkManager from "../../Utils/networkManager";
import React, { useEffect, useState } from "react";
import "../../App.css";
import {
  Route,
  Routes,
  Link,
  useNavigate,
  useParams,
  Outlet,
} from "react-router-dom";
import { Strings } from "../../Constants";
import backdrop from "./Assets/backdrop.png";
import windowView from "./Assets/windowView.png";
import payment from "./Assets/payment.png";
import location from "./Assets/location.png";
import calendar from "./Assets/calendar.png";
import share from "./Assets/share.png";
import { IJob } from "../../Models/Job.model";
import { getJobById } from "../../Controllers/apiController";
import background from "./Assets/background.png";
import OrangeBallSvg from "./Assets/OrangeBallSvg";

const JobPage = () => {
  // const tempEmployerId = ;
  const tempJobId = "61d5af2bae529a0009b7c580";
  const params = useParams();

  useEffect(() => {
    NetworkManager.init();
    // const getEmployerExample = getEmployer(tempEmployerId);
    // console.log(params?.employerId, params?.jobId);
    tempJobFetch(jobId);
  }, []);

  const queryParams = new URLSearchParams(window.location.search);
  const jobId = queryParams.get("jobId");
  const employerId = queryParams.get("employerId");
  const type = queryParams.get("type");

  const tempJobFetch = async (jobId: any) => {
    const jobByIdExample: IJob = await getJobById(jobId);
    setCurrJob(jobByIdExample);
    console.log("jobByIdExample: ", jobByIdExample);
  };

  const title = Strings.Navbar.APP_TITLE;
  const [currEmployer, setCurrEmployer] = useState({});

  const [currJob, setCurrJob] = useState<IJob>();
  const [currJobId, setCurrJobId] = useState({});
  const [currJobType, setCurrJobType] = useState();
  const [currJobSkills, setCurrJobSkills] = useState();
  const [currJobCategories, setCurrJobCategories] = useState();

  const jobUrl = "/public-jobs/:id";
  const allJobsUrl = "/public-jobs/";
  const jobsByEmployer = "/public-jobs/byEmployer/:id";
  const employerById = "/public-jobs/getEmployerById/:id";
  const jobType = "/public-jobs/jobTypes";
  const jobSkills = "/public-jobs/skills";
  const jobCategories = "/public-jobs/categories";

  const fetchCurrJobSetUrl = () => {
    // await ...
    setCurrJob(currJob);
    // setCurrJobId(tempData._id.$oid)
    // setCurrJobType(job.type.name)
    // setCurrJobSkills(#)
    // setCurrJobCategories(#)
  };

  const fetchCurrEmployer = () => {
    // await ...
    // setCurrEmployer(#);
  };

  const tempData = {
    _id: {
      $oid: "61d5af2bae529a0009b7c580",
    },
    tags: [
      {
        $oid: "60cb704f131a48158de3d8f0",
      },
      {
        $oid: "60cb7c89945ca915d1843e36",
      },
    ],
    isMulti: false,
    skills: [],
    shifts: [],
    status: 1,
    employerName: "היייייי",
    employerEmail: "test555@deebapp.com",
    employerPhoneNumber: "0546512018",
    type: {
      $oid: "60cb7be1945ca915d1843e2c",
    },
    description: "sadas",
    eventName: "",
    location: {
      coordinates: [31.804381, 34.655314],
      _id: {
        $oid: "61d5af2bae529a0009b7c581",
      },
      type: "Point",
      address: "אשדוד, ישראל",
      floor: "",
    },
    payment: 30,
    paymentType: "hourly",
    startDate: {
      $date: "2022-01-20T06:00:00.000Z",
    },
    endDate: {
      $date: "2022-01-20T11:00:00.000Z",
    },
    dressCode: "",
    isTips: false,
    isBill: false,
    openPosiotions: 3,
    numOfPosiotions: 3,
    employer: {
      $oid: "61b9fead18578f00098b0db4",
    },
    createdAt: {
      $date: "2022-01-05T14:46:03.819Z",
    },
    updatedAt: {
      $date: "2022-01-05T20:35:34.321Z",
    },
    __v: 0,
  };

  useEffect(() => {
    fetchCurrJobSetUrl();
    fetchCurrEmployer();
    return () => {};
  }, []);

  return (
    <div className="main-container">
      <img className="main-container-background" src={background} />
      <main className="main-Section">
        <div className="backdrop">
          <div className="backdrop-container">
            <div className="title-wrapper">
              <h3 className="title">משמרת שהיא בול בשבילך!</h3>
            </div>
            <h3 className="subtitle">
              אם חשבת אחרת, יש עוד עשרות משמרות דומות שמחכות לך
            </h3>
            <div className="window-container">
              <div className="window-header"></div>
              <div className="window-main-section">
                <div className="right-container">
                  <div className="general-info">
                    <div className="emp-logo-img">
                      {currJob?.employerImage ? (
                        <img
                          className="emp-logo-img"
                          src={currJob?.employerImage}
                          width="60px"
                          height="60px"
                        />
                      ) : (
                        <img
                          src="./Assets/employer_placeholder.png"
                          width="60px"
                          height="60px"
                        />
                      )}
                    </div>
                    <div className="info-container">
                      <div className="job-type-title">
                        {currJob?.type.name && currJob.type.name}
                      </div>
                      <div className="employer-name">
                        {currJob?.employerName}
                      </div>
                    </div>
                  </div>
                  <div className="job-description-title">תיאור המשרה</div>
                  <div className="job-description">{currJob?.description}</div>
                  <div className="bottom-container">
                    <div className="buttons-container">
                      <button className="open-app-button">תפסו את המשרה</button>
                      <button className="share-button">
                        <img src={share} width="60px" alt="wallet" />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="left-container">
                  <div className="payment-container">
                    <img src={payment} height="50px" alt="wallet" />
                    <div className="location-container">{`${currJob?.payment} ₪ / לשעה`}</div>
                  </div>
                  <div className="location-row">
                    {" "}
                    <img src={location} height="50px" alt="location" />
                    <div className="location-text">{`לוקיישן`}</div>
                    <div></div>
                  </div>
                  <div className="calendar-container">
                    <img src={calendar} height="50px" alt="calendar" />
                    <div className="calendar-text">{`calendar`}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <OrangeBallSvg
          style={{ position: "absolute", right: "250px", top: "200px" }}
        /> */}
      </main>
    </div>
  );
};

export default JobPage;
